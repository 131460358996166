import React from "react"
import { CarteCategory } from "../../../types"
import { buildCategoryMenuElement } from "../../../utils"
import Cadre from "../../Cadre/Cadre"

import "./Category.less"

interface CategoryComponentProps {
  category: CarteCategory
}

export default function CategoryComponent({
  category,
}: CategoryComponentProps) {
  return (
    <div className="menu-category-container">
      <Cadre
        color="#E6A133"
        widthX={2}
        widthY={0.8}
        ratioX={0.75}
        ratioY={0.5}
        paddingContent={0.5}
        widthLargeLine={1}
        widthLightLine={0.5}
        displayDivider={false}
        style={{ flex: 1, marginBottom: "1em" }}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          margin: "1.6vw 0",
        }}
      >
        <h2>{category.name}</h2>
      </Cadre>
      {category.items.map(item => buildCategoryMenuElement(item))}
    </div>
  )
}
